var SparkDigital = SparkDigital || {};

SparkDigital = (function () {

    /***********************************************************
    // GLOBAL - FOR STORING REUSABLE VARIABLES (EG. DOM)
    ***********************************************************/
    var global = {

            debug_mode: $('body').hasClass('debug-mode'), // Debug on-off state

            site_lang:  $('html').attr('lang'), // get site language

            ajax_endpoints: window["ajax_endpoints"],

            // Define ones that are being called everywhere
            elements: {
                body: $("body"),
                iframes: $("body.single iframe"), // Be specific where the iframes might be added
                inlineSmoothScroll: $("a[smooth-scroll], a[href^='#']"), // Select all inline anchors
                inlinePopUps: $('[inline-modal]'), // For all inline popups
                iframePopUps: $('[inline-youtube], [inline-video], [inline-gmaps]'), // For all iframe popups
                lazyload: $('[data-bg], img[data-src], iframe[data-src], video[data-src], .lazy'), // Lazy load bg images, img/iframe/video HTML elements. 
            },

        },
        /***********************************************************
        // COMPONENTS THAT ARE USED ON MORE THAN ONE PAGE & CALLABLE
        ***********************************************************/
        components = {
            modal: {
                inline: function (elements) {
                    var elements = $(elements).length != 0 ? $(elements) : global.elements.inlinePopUps;
                    
                    // if ($(elements).length > 0) {
                    //     $(elements).magnificPopup({
                    //         type: 'inline',
                    //         closeOnContentClick: true,
                    //         fixedContentPos: true,
                    //         mainClass: 'mfp-fade',
                    //         removalDelay: 160,
                    //     });
                    // }

                },
                iframe: function (elements) {
                    var elements = $(elements).length != 0 ? $(elements) : global.elements.iframePopUps;

                    if ($(elements).length > 0) {
                        // $(elements).magnificPopup({
                        //     //disableOn: 700,
                        //     type: 'iframe',
                        //     mainClass: 'mfp-fade',
                        //     removalDelay: 160,
                        //     preloader: false,
                        //     fixedContentPos: false
                        // });
                    }
                },
                gallery: function (elements) {
                    var elements = $(elements).length != 0 ? $(elements) : global.elements.galleries;
                    //console.log($(elements).length);
                    if ($(elements).length > 0) {

                        $(elements).magnificPopup({
                            type: 'image',
                            callbacks: {
                                open: function () {
                                    $('.headerFix').css('padding-right', 15);
                                }, //Fix for header moving when popup triggered
                                close: function () {
                                    $('.headerFix').css('padding-right', 0);
                                }
                            },
                            image: {
                                verticalFit: true,
                                titleSrc: function (item) {
                                    return item.el.parents('.gallery-item').find('.gallery-caption').text();
                                }

                            },
                            gallery: {
                                enabled: true,
                            },
                            mainClass: 'mfp-fade',
                            removalDelay: 160,
                            fixedContentPos: true,
                        });

                        // Separate remote trigger button to fire the magnificPopup gallery
                        var galleryHeaderImage = $(".postTopImage");
                        if ($(galleryHeaderImage).length > 0) {
                            $(galleryHeaderImage).click(function () {
                                $(elements).eq(0).trigger('click');
                                return false;
                            });
                        }
                    }

                },
            },
            privacy_consent: function(){
                // Generator: https://cookieconsent.insites.com
                $(document).ready(function() {

                    // Ensure CookieConsent JS Plugin exists
                    if (typeof window.cookieconsent !== "undefined") { 
	                
                        // Multisite    
                        switch ( global.site_lang ) {
                            // case "fr-FR": // French
                            //    var privacy_content = {
                            //         "message": "Ce site Internet utilise des cookies pour améliorer et personnaliser votre expérience. Pour plus d’informations sur la collecte et l’utilisation de vos informations, y compris l’utilisation de cookies, merci de consulter notre ",
                            //         "link": "politique de confidentialité",
                            //         "dismiss": "Ok",
                            //         "href": "/fr-fr/politique-de-confidentialite",
                            //         "target": "_blank",
                            //    };
                            // 	break;
                                
                            default: // Defaults to English
                                var privacy_content = {
                                    "message": "This website uses cookies to enhance and personalise your experience. For more information about our collection and use of your information, including our use of cookies, please check out our ",
                                    "link": "privacy policy",
                                    "dismiss": "Ok",
                                    "href": "/privacy-policy",
                                    "target": "_blank",
                                };
                        };                
                        
                        window.cookieconsent.initialise({
                            //dismissOnTimeout: 10,
                            position: "bottom-right",
                            palette: {
                                "popup": {
                                    //"text": "#fff",
                                    "background": "rgba(38,38,38,0.95)"
                                }
                            },
                            elements: {
                            //header: '<span class="cc-header">{{header}}</span>&nbsp;',
                            //"message": "<div id='cookieconsent:desc'><p class='small cc-message' style='color:#fff'>{{message}}.</p></div>",
                            "messagelink": "<div id='cookieconsent:desc'><p class='small cc-message' style='color:#fff'>{{message}} <a aria-label='learn more about cookies' tabindex='0' class='cc-link' href='{{href}}' target='_blank'>{{link}}</a>.</p></div>",
                            "dismiss": "<a aria-label='dismiss cookie message' tabindex='0' class='buttonDefault cc-btn cc-dismiss small'>{{dismiss}}</a>"
                            },
                            content: privacy_content,
                        });

                    }

                });
            },
            smoothScroll: function (elements) {

                var elements = $(elements).length != 0 ? $(elements) : global.elements.inlineSmoothScroll;
                if ($(elements).length > 0) {
                    elements.click(function () {

                        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname && this.hash.slice(1) != "#" && this.hash.slice(1) != "" ) {
                            var target = $(this.hash);                            
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                            if (target.length) {
                                $('html, body').animate({
                                    scrollTop: target.offset().top - 150
                                }, 1500);
                                return false;
                            }
                        }

                    });
                }
            },
            stickyHeader: function(){
                // Uses Polyfill for browsers that does not support IntersectionObserver API
                
                //  Navigation target
                var sticky_nav = document.querySelector("header");

                // Sticky Target
                var sticky_target = document.querySelector("#sticky-header-target");
                
                var observer = new IntersectionObserver(function(entries) {
                    // no intersection with screen
                    if(entries[0].intersectionRatio === 0) {
                        $(sticky_nav).addClass('sticky');
                        if (global.debug_mode) {
                            console.log("Sticky Target: Outside");
                        }
                    }
                    // fully intersects with screen
                    else if(entries[0].intersectionRatio === 1){
                        $(sticky_nav).removeClass('sticky');
                        if (global.debug_mode) {
                            console.log("Sticky Target: Inside");
                        }
                    }
                
                }, { threshold: [0,1] });
                
                observer.observe(sticky_target);
            },

            // Compiles HandleBars
            handlebars: {

                // Use for tracking initialisation state
                init: false,

                // Render Template
                render: function( templateElement ){

                    // Initialise helper if haven't 
                    if(this.init === false) this.extend();

                    if(templateElement === "" || templateElement === null ){
                        return false;
                    }
                    
                    //var templateSource = document.getElementById( $(templateElement).attr("ID") ).innerHTML;
                    var templateSource = $(templateElement).html();
                    
                    //console.log( Handlebars.compile(templateSource) );
                    
                    if(templateSource !== "" || templateSource !== null){
                        // Compile handlebars and return
                        return Handlebars.compile(templateSource);
                    }
                    return false;
                },

                // Extend such as additional kelpers
                extend: function(){

                    // debug helper
                    // usage: {{debug}} or {{debug someValue}}
                    // from: @commondream (http://thinkvitamin.com/code/handlebars-js-part-3-tips-and-tricks/)
                    Handlebars.registerHelper("debug", function (optionalValue) {
                        console.log("Current Context");
                        console.log("====================");
                        console.log(this);

                        if (optionalValue) {
                            console.log("Value");
                            console.log("====================");
                            console.log(optionalValue);
                        }
                    });

                    Handlebars.registerHelper('reverse', function (arr) {

                        //console.log(typeof(arr))
                        if (global.polyfill_isArray(arr)) {
                            arr.reverse();
                        } else {
                            arr;
                        }

                    });

                    Handlebars.registerHelper('str_concatnate', function (a, b, opts) {
                        var str_combined = a + '' + b;
                        return new Handlebars.SafeString(str_combined);
                    });

                    Handlebars.registerHelper('str_replace', function (a, b, c, opts) {
                        var str_replaced = a.replace(b, c);
                        return new Handlebars.SafeString(str_replaced);
                    });

                    // Provides conditional string checks
                    Handlebars.registerHelper('if_eq', function (a, b, opts) {
                        if (a === b) {
                            return opts.fn(this);
                        } else {
                            return opts.inverse(this);
                        }
                    });

                    // Checks if string contains
                    Handlebars.registerHelper('if_contains', function (a, b, opts) {
                        if (a.indexOf(b) >= 0) {
                            return opts.fn(this);
                        } else {
                            return opts.inverse(this);
                        }
                    });

                    // Checks if obj contains
                    Handlebars.registerHelper('if_obj_contains', function (a, b, c, opts) {

                        var obj_founds = global.search_object_by_key(a, b, c);

                        if (obj_founds.length > 0) {
                            return opts.fn(this);
                        } else {
                            return opts.inverse(this);
                        }
                    });

                    // Comparsion Check
                    Handlebars.registerHelper('if_compare', function (lvalue, operator, rvalue, opts) {

                        var operators, result;

                        if (arguments.length < 3) {
                            throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
                        }

                        if (opts === undefined) {
                            opts = rvalue;
                            rvalue = operator;
                            operator = "===";
                        }

                        operators = {
                            '==': function (l, r) {
                                return l == r;
                            },
                            '===': function (l, r) {
                                return l === r;
                            },
                            '!=': function (l, r) {
                                return l != r;
                            },
                            '!==': function (l, r) {
                                return l !== r;
                            },
                            '<': function (l, r) {
                                return l < r;
                            },
                            '>': function (l, r) {
                                return l > r;
                            },
                            '<=': function (l, r) {
                                return l <= r;
                            },
                            '>=': function (l, r) {
                                return l >= r;
                            },
                            'typeof': function (l, r) {
                                return typeof l == r;
                            }
                        };

                        if (!operators[operator]) {
                            throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);
                        }

                        result = operators[operator](lvalue, rvalue);

                        if (result) {
                            return opts.fn(this);
                        } else {
                            return opts.inverse(this);
                        }

                    });

                    //  Format a date
                    //  @link https://github.com/phstc/jquery-dateFormat
                    Handlebars.registerHelper('DateFormat', function (input, dateformat) {

                        // Normalize date to unix time
                        var unixTimestamp = new Date().getTime(input);
                        unixTimestamp = new Date(unixTimestamp).toISOString();

                        dateformat = dateformat == '' ? 'dd/MM/yyyy' : dateformat;

                        if (dateformat == 'pretty') {
                            return new Handlebars.SafeString($.format.prettyDate(new Date(unixTimestamp)));
                        } else {
                            return new Handlebars.SafeString($.format.date(new Date(unixTimestamp), dateformat));
                        }

                    });


                    // Finished initialisation
                    this.init = true;

                }
  
  
            },

            lazyLoad: function(){

                if(typeof(LazyLoad) != 'undefined'){

                    // @link https://github.com/verlok/lazyload
                    // Instantiate LazyLoad and make available for in Global JS scope
                    window.lazyLoadInstance = new LazyLoad({      
                        //use_native: true, // Use browser native implementation if available
                        load_delay: 300, // Set delay loading when there's inline anchor so that it scrolls to the right spot
                    }, global.elements.lazyload );

                    /* 
                    BROWSER'S NATIVE PRINT EVENTS LISTENERS
                    --------------------------------------- 
                    In order to make browsers load all the images before printing
                    even if the user doesn't press the custom print button. 
                    NOTE that this works only partially in some browsers! */
                    var isSafari = /^((?!chrome|android).)*safari/i.test(
                        navigator.userAgent
                    );
                    if (!isSafari) {
                        window.onbeforeprint = function() {
                            window.lazyLoadInstance.loadAll();
                        };
                    } else {
                        // Safari doesn't support the onbeforeprint event
                        var mediaQueryList = window.matchMedia("print");
                        mediaQueryList.addListener(function(mql) {
                            if (mql.matches) {
                                window.lazyLoadInstance.loadAll();
                            }
                        });
                    }

                }

            },

            tabs: {
            },

            wow: function() {

                if(typeof(WOW) != 'undefined'){
                    //Wow js
                    wow = new WOW({
                        mobile:true,     
                    })
                    wow.init();
                }

            },
            
            // Loads more post via ajax
            ajax_load: function(){


                if($("script#template-news-and-insights").length > 0){
                    
                    // Load Handlebars template
                    var HTMLTemplate = components.handlebars.render( $("script#template-news-and-insights") );
                    
                    if( HTMLTemplate !== false){                                            
                
                        // Lazy load more content
                        $('a.news-read-more').click( function (event) {

                            // Self
                            var el = $(this);

                            // Content Container
                            var targetContainer = $(".news-articles-inner:first-of-type");

                            // Get last new item timestamp to determine for use as part of the rest api
                            var lastNewsItem = $(".news-article-col:last-of-type");
                            var lastNewsItemTime = $(lastNewsItem).find("time");

                            if(global.ajax_endpoints.get_posts !== undefined && el.attr("disabled") != false && lastNewsItemTime.length > 0 && targetContainer.length > 0  ){

                                // Stop from user from multiple clicks
                                el.attr("disabled", true);

                                // Construct REST API QUERY parameters
                                var params = {
                                    "end_date": lastNewsItemTime.data("timestamp"),
                                    "posts_per_page": 9,

                                };


                                $post_tags_filter = $(el).data("type-ids"); // Eg data-type-ids="data-id-030"

                                if($post_tags_filter != undefined){
                                    $post_tags_filter = $post_tags_filter.replace(/data-id-0/gi, "");
                                    params.tag__in = $post_tags_filter;
                                }

                                var xhr = $.ajax({
                                    url: global.ajax_endpoints.get_posts,
                                    method : "GET",
                                    //dataType : "json",
                                    data: params,
                                    statusCode: {
                                        404: function() {
                                            console.log( "Error requesting data" );
                                        }
                                    }
                                });

                                $.when(xhr).done(function(response){
                                
                                    if(response != null && response.found_posts > 0 ){

                                        // Ensure that the returing string contains img src
                                        var posts = response.posts;
                                    
                                        // Collect output
                                        var data_output = new Array;

                                        // Compile data into HTML using Handlebars
                                        var outputHTML = HTMLTemplate( posts );

                                        // Inject HTML
                                        $(outputHTML).fadeOut();
                                        $(outputHTML).insertAfter( lastNewsItem );

                                        $(outputHTML).fadeIn();
                                        
                                        if (window.lazyLoadInstance ) {
                                            window.lazyLoadInstance.update();
                                        }

                                        // Hide more button if loaded all posts
                                        if(response.found_posts < 9){
                                            $(el).fadeOut();
                                        }

                                    }
        
                                });
                                

                            }
                            
                            return false;

                        });
                    
                    }                    

                }



            }


        },
        /***********************************************************
        // PAGE SPECIFIC FUNCTIONS
        ***********************************************************/
        pages = function () {
            
            // Specific functions only required Use CSS classname on body tag
            if ($(global.elements.body).hasClass('page-home')) { // Home or Front-Page
                //Team carousel - Owl Carousel

         var owl = $('#team-carousel').owlCarousel({
                    loop:true,
                    margin:30,
                    nav:false,
                    dots:true,
                    lazyLoad: true,
                    responsive:{
                        0:{
                            items:1
                        },
                        600:{
                            items:3
                        },
                        1000:{
                            items:3
                        }
                    }
                });
            //Call lazyload function on change
            owl.on('changed.owl.carousel', function(event) {
               window.lazyLoadInstance.update();
            })


    
            }

            if ($(global.elements.body).hasClass('post') ) { // Post or Page

            }

            if ($(global.elements.body).hasClass('search')) { // Search
                // Inline Code here ...
            }

            if ($(global.elements.body).hasClass('archives')) { // Archives, Catgory, Tags, etc.
                // Inline Code here ...
            }

            if ($(global.elements.body).hasClass('category') || $(global.elements.body).hasClass('category-news')) { // Category, Archives, Tags, etc.
                // Inline Code here ...
            }

            if ($(global.elements.body).hasClass('page-template-default')) {
                // Inline Code here ...
            }

            if ($(global.elements.body).hasClass('page')) {
                //Testimonial carousel - Owl Carousel
                $('#testimonial-carousel').owlCarousel({
                    loop:false,
                    margin:0,
                    nav:false,
                    dots:true,
                    items:1,
                    lazy: true,
                    autoHeight: true,
                    animateOut: 'fadeOut',
                    animateIn: 'fadeIn',
                    autoplay:true,
                    autoplayTimeout:10000,
                    autoplaySpeed:2000
                });

                //FAQ Accordian
                if($( ".faq-head" ).length > 0){
                    $( ".faq-head" ).click(function() {
                        $('.faq-content').slideUp("slow");
                        $('.faq-head').removeClass('faq-head-active');
                        // $(".drop_active").css("display","none");
                        // $(".drop_default").css("display","block");
                        if ($(this).next('div').is(':hidden'))
                            {  
                                $(this).next('div').slideDown();
                                // $(".drop_default" ,this).css("display","none");
                                // $(".drop_active" ,this).css("display","block");
                                $(this).addClass('faq-head-active');
                            }
                        else
                            {
                                $(this).next('div').slideUp();
                                // $(".drop_default" ,this).css("display","block");
                                // $(".drop_active" ,this).css("display","none");
                                $(this).removeClass('faq-head-active');
                            }
                        
                        return false;

                    });
                    
                    // Initialise be expanding the first
                    $( ".faq-head" ).first().click();

                }
            }
            


            /* Other non-page specific functions for every page */
            /*
            $(global.elements.iframes).each(function (i, iframe) {

                //Exclude Ads from Responsive iframe wrapper
                if ($(iframe).src !== '//tpc.googlesyndication.com/safeframe/') {
                    //$(iframe).wrap("<div class='embed-responsive embed-responsive-16by9'></div>");
                }
            });
            */








        },

        /***********************************************************
        // Other functions as required
        ***********************************************************/
        misc_func = function () {};

        // Initialise JS Class and functions
        return {
            
            init: function () {
                // DEBUG RELATED
                if (global.debug_mode) {
                    console.log("Debug Mode: " + global.debug_mode);
                }

                // Page Specific functions
                pages();

                // Components Used throughout the site
                components.stickyHeader();
                components.wow();
                components.lazyLoad();
                components.modal.inline();
                components.modal.iframe();
                components.privacy_consent();
                components.smoothScroll();
                components.ajax_load();

            }
        };

}());


function startCarousel(){
    //Service carousel - Owl Carousel
    $('#service-carousel').owlCarousel({
        loop:true,
        margin:30,
        nav:false,
        dots:true,
        items:1,
        autoHeight: true

    });
}
function stopCarousel() {
  var owl = $('#service-carousel');
  owl.trigger('destroy.owl.carousel');
  owl.addClass('off');
}

$('.slide-element').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            LazyLoad.update();
        });


$(document).ready( function() {
    SparkDigital.init();

    if ( $(window).width() < 767 ) {
        startCarousel();
    }else{
       $('#service-carousel').addClass('off');
    }
});

$(window).resize(function() {
    setTimeout(function() {
       if ( $(window).width() < 767 ) {
          startCarousel();
        } else {
          stopCarousel();
        }
       
    },120);
});// end window resize function


